/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import wrapped from './wrappers';

export const wrapRootElement = wrapped;

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!`IntersectionObserver` in window) {
    import(`intersection-observer`);
  }
};
