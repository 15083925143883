// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-tsx": () => import("./../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-diagnosis-tsx": () => import("./../src/templates/diagnosis.tsx" /* webpackChunkName: "component---src-templates-diagnosis-tsx" */),
  "component---src-templates-standard-page-tsx": () => import("./../src/templates/standard-page.tsx" /* webpackChunkName: "component---src-templates-standard-page-tsx" */),
  "component---src-templates-consultation-therapist-tsx": () => import("./../src/templates/consultation-therapist.tsx" /* webpackChunkName: "component---src-templates-consultation-therapist-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-directory-tsx": () => import("./../src/pages/directory.tsx" /* webpackChunkName: "component---src-pages-directory-tsx" */),
  "component---src-pages-evaluation-tsx": () => import("./../src/pages/evaluation.tsx" /* webpackChunkName: "component---src-pages-evaluation-tsx" */),
  "component---src-pages-explore-tsx": () => import("./../src/pages/explore.tsx" /* webpackChunkName: "component---src-pages-explore-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lgbt-therapists-tsx": () => import("./../src/pages/lgbt-therapists.tsx" /* webpackChunkName: "component---src-pages-lgbt-therapists-tsx" */),
  "component---src-pages-match-tsx": () => import("./../src/pages/match.tsx" /* webpackChunkName: "component---src-pages-match-tsx" */),
  "component---src-pages-matches-tsx": () => import("./../src/pages/matches.tsx" /* webpackChunkName: "component---src-pages-matches-tsx" */),
  "component---src-pages-therapist-tsx": () => import("./../src/pages/therapist.tsx" /* webpackChunkName: "component---src-pages-therapist-tsx" */),
  "component---src-pages-user-tsx": () => import("./../src/pages/user.tsx" /* webpackChunkName: "component---src-pages-user-tsx" */)
}

