import React from 'react';
import { ThemeProvider } from 'styled-components';
import { AuthGateProvider } from './src/context/registerModal';

// eslint-disable-next-line react/display-name,react/prop-types
const Wrapped = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts

  // @TODO: NOTES/ Minimal business logic can go in wrapRootElement - no hooks.
  return <AuthGateProvider>{element}</AuthGateProvider>;
};

export default Wrapped;
