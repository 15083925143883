import React, { useContext, useState } from 'react';

const AuthGateContext = React.createContext(null);

export const useAuthGate = () => {
  return useContext(AuthGateContext);
};

export const AuthGateProvider: React.FunctionComponent = props => {
  const [triggered, setTriggered] = useState<boolean>(false);
  return (
    <AuthGateContext.Provider value={[triggered, setTriggered]} {...props} />
  );
};
