module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"consentOnContinuedBrowsing":false,"whitelabel":false,"lang":"en","siteId":2179853,"cookiePolicyId":46089675,"cookiePolicyUrl":"https://www.helsahelps.com/Privacy","cookiePolicyInOtherWindow":true,"banner":{"acceptButtonDisplay":true,"acceptButtonColor":"#ffffff","acceptButtonCaptionColor":"#010000","rejectButtonDisplay":true,"rejectButtonColor":"#010101","rejectButtonCaptionColor":"white","position":"float-top-center","textColor":"white","backgroundColor":"#7bb2a1","acceptButtonCaption":"OK, that's fine","rejectButtonCaption":"Decline","cookiePolicyLinkCaption":"Privacy & Cookie Policy","content":"<div id=iubenda-cs-title>Cookie Policy</div><div id=iubenda-cs-paragraph>This website or its third-party tools use cookies for its necessary functioning, to improve your browsing experience and for analytics. To learn more, please refer to our <a href=https://www.helsahelps.com/Privacy class=iubenda-cs-cookie-policy-lnk target=_blank rel=noopener>Privacy & Cookie Policy</a>.<br /></div>"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.helsahelps.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"jid8jwf"},"google":{"families":["Montserrat:200,300,400,500,600,700,800,900"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-166068942-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K6F42MK"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
